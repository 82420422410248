const envConfig = {
  API_KEY: process.env.REACT_APP_API_KEY ?? 'd8227a7b973547adad7e01a11f672f23',
  API_URL: process.env.REACT_APP_API_URL ?? 'https://el-cubo-prod-api.azurewebsites.net/api/',
  APP_UTILITY_KEY: process.env.REACT_APP_UTILITY_KEY ?? 'utility-b60bbed139054e2289dfa5d29e4c63aab495f236d3af48168378c41dc671d643',
  BOOKING_API_URL: process.env.REACT_APP_BOOKING_API_URL ?? 'https://el-cubo-api-booking.azurewebsites.net/api/',
  BOOKING_API_KEY: process.env.REACT_APP_BOOKING_API_KEY ?? '#{REACT_APP_BOOKING_API_KEY}#',
  HUB_URL: process.env.REACT_APP_HUB_URL ?? 'https://el-cubo-prod-api.azurewebsites.net/',
  PAYPAL_CLIENTID: process.env.REACT_APP_PAYPAL_CLIENTID ?? '#{REACT_APP_PAYPAL_CLIENTID}#',
  GEO_API_KEY: process.env.REACT_APP_GEO_API_KEY ?? '#{REACT_APP_GEO_API_KEY}#',
  SearchItemsPageSize: process.env.REACT_APP_SEARCH_PAGE_SIZE ?? '#{REACT_APP_SEARCH_PAGE_SIZE}#',
  StorageKey: process.env.REACT_APP_STORAGE_KEY ?? 'cubo', 
  Prefix: process.env.REACT_APP_PREFIX ?? 'cubo', 
  DefaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'en', 
  TemplateStyle: process.env.REACT_APP_TEMPLATE_STYLE ?? '#{REACT_APP_TEMPLATE_STYLE}#',
  TemplateVersion: process.env.REACT_APP_TEMPLATE_VERSION ?? '#{REACT_APP_TEMPLATE_VERSION}#',
  HeaderVersion: process.env.REACT_APP_HEADER_VERSION ?? '#{REACT_APP_HEADER_VERSION}#',
  SubHeaderVersion: process.env.REACT_APP_SUBHEADER_VERSION ?? '#{REACT_APP_SUBHEADER_VERSION}#',
  StoreVersion: process.env.REACT_APP_STORE_VERSION ?? '#{REACT_APP_STORE_VERSION}#',
  WelcomeLong: process.env.REACT_APP_WELCOME_LONG ?? '#{REACT_APP_WELCOME_LONG}#',
  WelcomeShort: process.env.REACT_APP_WELCOME_SHORT ?? '#{REACT_APP_WELCOME_SHORT}#',
  DepartmentsStyle: process.env.REACT_APP_DEPARTMENTS_STYLE ?? '#{REACT_APP_DEPARTMENTS_STYLE}#',
  DepartmentCarouselSize: 6,
  CardBorderStyle: process.env.REACT_APP_CARD_BORDER_STYLE ?? '#{REACT_APP_CARD_BORDER_STYLE}#',
  ButtonStyle: process.env.REACT_APP_BUTTON_STYLE ?? '#{REACT_APP_BUTTON_STYLE}#',
  SocialFooterVersion: process.env.REACT_APP_SOCIAL_FOOTER_VERSION ?? '#{REACT_APP_SOCIAL_FOOTER_VERSION}#',
  InstagramLink: process.env.REACT_APP_SOCIAL_INSTAGRAM ?? '#{REACT_APP_SOCIAL_INSTAGRAM}#',
  FacebookLink: process.env.REACT_APP_SOCIAL_FACEBOOK ?? '#{REACT_APP_SOCIAL_FACEBOOK}#',
  WelcomeVersion: process.env.REACT_APP_WELCOME_VERSION ?? '#{REACT_APP_WELCOME_VERSION}#',
  PayPalForBookings: process.env.REACT_APP_PAYPAL_BOOKING ?? '#{REACT_APP_PAYPAL_BOOKING}#',
  AthMovilForBookings: process.env.REACT_APP_ATHMOVIL_BOOKING ?? '#{REACT_APP_ATHMOVIL_BOOKING}#',
  AthMovilPublicKey: process.env.REACT_APP_ATHMOVIL_PUBLIC_KEY ?? '#{REACT_APP_ATHMOVIL_PUBLIC_KEY}#',
  AthMovilPrivateKey: process.env.REACT_APP_ATHMOVIL_PRIVATE_KEY ?? '#{REACT_APP_ATHMOVIL_PRIVATE_KEY}#',
  PayPalKey: process.env.REACT_APP_PAYPAL_KEY ?? 'AffVAml-9Va0Qj64XqlxmbeIFyTZ4kp9vZKkVBOtLpoBoHfpG8E97dM_QeilJcrxLpyzPWAzssPpusw6',
  AllowCreditCardCreate: process.env.REACT_APP_ALLOW_CARD_CREATE ?? '#{REACT_APP_ALLOW_CARD_CREATE}#',
  ShowPaymentButtonOnBookingReservation: process.env.REACT_APP_SHOW_PAYMENT_ON_BOOKING ?? '#{REACT_APP_SHOW_PAYMENT_ON_BOOKING}#',
  ShowButtonOnBookingReservation: process.env.REACT_APP_SHOW__ON_BOOKING ?? '#{REACT_APP_SHOW__ON_BOOKING}#',
  FacebookPixelId: process.env.REACT_APP_FACEBOOK_PIXEL_ID ?? '#{REACT_APP_FACEBOOK_PIXEL_ID}#',
  GoogleAnalyticsTrackingId: process.env.REACT_APP_GA_TRACKING_ID ?? '#{REACT_APP_GA_TRACKING_ID}#',
  BrainTreeTokenizationKey: process.env.REACT_APP_BRAINTREE_TOKENIZATION_KEY ?? '#{REACT_APP_BRAINTREE_TOKENIZATION_KEY}#',
  ContactEmail: process.env.REACT_APP_CONTACT_EMAIL ?? 'contact@thecubepr.com',
  JobsEmail: process.env.REACT_APP_JOBS_EMAIL ?? 'jobs@thecubepr.com',
  HasReferrals: process.env.REACT_APP_HAS_REFERRALS_ENABLED ?? '#{REACT_APP_HAS_REFERRALS_ENABLED}#',
  CardVersion: process.env.REACT_APP_CARD_VERSION ?? '#{REACT_APP_CARD_VERSION}#',
  SidebarVersion: process.env.REACT_APP_SIDEBAR_VERSION ?? '#{REACT_APP_SIDEBAR_VERSION}#',
  WebstoreId: process.env.REACT_APP_WEDSTORE_ID ?? '#{REACT_APP_SIDEBAR_VERSION}#',
  AllowCreditCardsInBooking: process.env.REACT_APP_CREDITCARD_BOOKING ?? '#{REACT_APP_CREDITCARD_BOOKING}#',
  AllowCreditCardsInWebstore: process.env.REACT_APP_CREDITCARD_WEBSTORE ?? '#{REACT_APP_CREDITCARD_WEBSTORE}#',
  CounterVersion: process.env.REACT_APP_COUNTER_VERSION ?? '#{REACT_APP_COUNTER_VERSION}#',
  ShowWebstore: process.env.REACT_APP_SHOW_WEBSTORE ?? '#{REACT_APP_SHOW_WEBSTORE}#'
};

export default envConfig;
