import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  useRecoilState,
  useSetRecoilState,
  useResetRecoilState
} from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';
import {
  locationAtom,
  notificationsAtom,
  tokenAtom,
  userAtom,
  stateAtom,
  isLoggingInAtom,
} from '../../../../atoms/Atoms';
import Form from '../../../shared-components/form/Form';
import Input from '../../../shared-components/form/Input';
import Button from '../../../shared-components/buttons/Button';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';


function LoginForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();  
  const [locations, setLocations] = useRecoilState(locationAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const setIsLoggingIn = useSetRecoilState(isLoggingInAtom);
  const setUser = useSetRecoilState(userAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const setState = useSetRecoilState(stateAtom);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(`${t('valid_email_validation')}`)
        .required(`${t('required_field')}`),
      password: Yup.string().required(`${t('required_field')}`),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setIsLoggingIn(true);
      
      api.post(
          'oauth/local/login',
          {
            Username: values.email,
            Password: values.password,
          }
        )
        .then((response) => {
          if (response.data.error) {
            setNotifications([
              ...notifications,
              {
                title: `${t('oops')}`,
                description:
                `${t('login_error')}`,
                error: true,
              },
            ]);
          } else {
            localStorage.setItem(
              `${envConfig.StorageKey}-state`,
              JSON.stringify(response.data.data.state)
            );
            localStorage.setItem(
              `${envConfig.StorageKey}-user`,
              JSON.stringify(response.data.data.state.profile)
            );
            localStorage.setItem(
              `${envConfig.StorageKey}-token`,
              JSON.stringify(response.data.data.accessToken)
            );
            setState(response.data.data.state);
            setUser(response.data.data.state.profile);
            setToken(response.data.data.accessToken);         
            if (location.state?.state?.from === '/register') {
              navigate("/")
            } else if (location.state?.state?.from === 'locations-modal') {
              navigate("/")
              setLocations({ ...locations, modal: true });
            } else if (location.state?.state?.from) {          
              navigate(location.state?.state?.from);
            } else {
              navigate("/")
            }
          }
          setSubmitting(false);
          setIsLoggingIn(false);
        })
        .catch((error) => {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description: error.message,
              error: true,
            },
          ]);
          setSubmitting(false);
          setIsLoggingIn(false);
        });
    },
  });

  return (
    <>
      <Form>
        <Input
          label={t('email')}
          required
          type="email"
          name="email"
          id="email"
          placeholder="pedro@gmail.com"
          autoComplete="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={
            formik.errors.email && formik.touched.email && formik.errors.email
          }
          className="col-span-2"
        />
        <Input
          label={t('password')}
          required
          id="password"
          name="password"
          type="password"
          autoComplete="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          placeholder="***"
          error={
            formik.errors.password &&
            formik.touched.password &&
            formik.errors.password
          }
          className="col-span-2"
        />
      </Form>
      <div className="flex flex-row">
      <Button
        text={t('cancel')}
        textColor="text-gray-200"
        onClick={(e) => {
          e.preventDefault();
          navigate('/');
        }}
        className="bg-red-500 hover:bg-red-600 w-20 mr-2"
      />
      <Button
        text={t('signin')}
        textColor="text-gray-200"
        disabled={formik.isSubmitting}
        loading={formik.isSubmitting}
        onClick={formik.submitForm}
        loaderColor="bg-white"
        className="bg-yellow-500 hover:bg-yellow-600 w-20 ml-2"
      />
      </div>
    </>
  );
}

export default LoginForm;
