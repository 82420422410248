import './App.css';
import '../assets/css/custom.css';
import React, { useEffect, useState } from 'react';
import { useLocalStorage, useMount } from 'react-use';
import envConfig from '../envConfig';
import {
  useRecoilValue, useRecoilState, useSetRecoilState,
} from 'recoil';
import routes from '../routes/index';
import _ from 'lodash';
import { AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-use';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { stateAtom, themeAtom, tokenAtom, userAtom } from '../atoms/Atoms';
import ProtectedRoute from '../routes/ProtectedRoutes';
import Header from './shared-components/header/Header';
import Footer from './shared-components/footer/Footer';
import { useTranslation } from 'react-i18next';
import Navbar from './shared-components/navbar/Navbar';
import Logo from './shared-components/logo/Logo';
import Notifications from './shared-components/notifications/Notifications';
import NewHeader from './shared-components/header/NewHeader';
import WarningBanner from './shared-components/warning-banner/WarningBanner';
import bookingApi from '../api/bookingApi';

function App() {
  const { i18n } = useTranslation();
  const theme = useRecoilValue(themeAtom);
  const location = useLocation();
  const [language] = useLocalStorage(`${envConfig.Prefix}-lang`);
  const [token, setToken] = useRecoilState(tokenAtom);
  const [state, setState] = useRecoilState(stateAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const [fetchedState, setFetchedState] = useState(false);

  function getCurrentUserState() {
    if (user && !fetchedState) {
      setFetchedState(true);
      bookingApi.get('auth/refreshstate')
        .then((response) => {
          console.log('refreshstate', response.data.data.profile);
          localStorage.setItem(
            `${envConfig.StorageKey}-user`,
            JSON.stringify(response.data.data.profile)
          );
          // localStorage.setItem(
          //   `${envConfig.StorageKey}-state`,
          //   JSON.stringify(response.data.data.state)
          // );
          setUser(response.data.data.profile);
          //setState(response.data.data.state);
        }).catch((err) => {
          // console.error('refreshstate', err);
          setUser(null);
          setToken(null);
          localStorage.removeItem(`${envConfig.StorageKey}-user`);
          localStorage.removeItem(`${envConfig.StorageKey}-token`);
        });
    } else if (!user) {
      setFetchedState(false);
    }
  }

  const getUser = () => {
    const savedProfile = localStorage.getItem(
      `${envConfig.StorageKey}-user`,
    );
    if (savedProfile) setUser(JSON.parse(savedProfile));
  };

  const getState = () => {
    const savedState = localStorage.getItem(
      `${envConfig.StorageKey}-state`,
    );
    if (savedState) setState(JSON.parse(savedState));
  };
 
  const getToken = () => {
    const accessToken = localStorage.getItem(
      `${envConfig.StorageKey}-token`
    );
    setToken(accessToken);
  };

  useMount(() => {  
    if(language) { localStorage.setItem(`${envConfig.Prefix}-lang`,JSON.stringify(language)) }
    else {
      i18n.changeLanguage('en');
      localStorage.setItem(`${envConfig.Prefix}-lang`,JSON.stringify('en'))
    }
    getToken();
    getState();
    getUser();
  });

  useEffect(() => {
    if(language) { localStorage.setItem(`${envConfig.Prefix}-lang`,JSON.stringify(language)) }
    else {
      i18n.changeLanguage('en');
      localStorage.setItem(`${envConfig.Prefix}-lang`,JSON.stringify('en'))
    }
  }, []);

  useEffect(() => {
    getCurrentUserState();
  }, [user]);

  return (
    <div 
      className={`${theme} fontDefaults flex flex-col md:h-screen md:overflow-hidden antialiased`}
  >

    <Router>
    <Navbar/>
    <Header/>
    <WarningBanner />
    {/* <NewHeader /> */}
    <Logo/>
      <AnimatePresence key="app" mode="exit" initial={false}>
        <Routes location={location} key={location.pathname}>
          {_.map(routes, (route) => {
            if (route.protected) {
              return (
                <ProtectedRoute
                  key={route.id}
                  path={route.path}
                  exact={route.exact}
                  element={route.element}
                />
              );
            }
            return (
              <Route
                key={route.id}
                path={route.path}
                exact={route.exact}
                element={<route.element />}
              />
            );
          })}
        </Routes>  
        <Notifications/> 
      </AnimatePresence>
      {/* <Footer/> */}
    </Router>

    </div>
  
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
